import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Seo } from "../gatsby-components"
import { Layout } from "../components"
import {
  FlexibleGridBlock,
  FlexibleGridBlockItem,
  FlexibleGridBlockStack,
  FlexibleGridBlockImage,
} from "../blocks"
import { layout } from "../temp-content/layout"

const InArbeitPage = () => {
  return (
    <>
      <Seo title="In Arbeit" />
      <Layout {...layout}>
        <div className="grid gap-8 sm:gap-12">
          {/* First Row */}
          <FlexibleGridBlock
            cols={3}
            items={[
              <FlexibleGridBlockItem colSpan={2}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/in-arbeit-temp/Bild_1_final.jpg"
                      alt="firstleft"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
              <FlexibleGridBlockStack
                colSpan={1}
                items={[
                  <StaticImage
                    src="../images/in-arbeit-temp/Bild_2_P2.jpg"
                    alt="firstRightTop"
                  />,
                  <StaticImage
                    src="../images/in-arbeit-temp/BS_Rendering-Ecke-Schankstrasse-final-flat.jpg"
                    alt="firstRightBottom"
                  />,
                ]}
              />,
            ]}
            noPaddingTop
            noPaddingBottom
          />
          {/* Second Row */}
          <FlexibleGridBlock
            noPaddingTop
            // noPaddingBottom
            items={[
              <FlexibleGridBlockItem>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/in-arbeit-temp/S_2.preview-flat.jpg"
                      alt="second"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
            ]}
          />
        </div>
      </Layout>
    </>
  )
}

export default InArbeitPage;
